;(function ($) {
  $( ".uk-card-location" ).hover(
    function() {
      var getCount = $(this).data('count');
      $('.map-item-'+getCount).addClass( "hover" );
    }, function() {
      $('.map-item').removeClass( "hover" );
    }
  );

  $( ".map-item" ).hover(
    function() {
      var getCount = $(this).data('count');
      $('.uk-card-location-'+getCount).addClass( "hover" );
    }, function() {
      $('.uk-card-location').removeClass( "hover" );
    }
  );
})(jQuery);