(function ($) {

  typedInit2();

  function typedInit2() {
    var el = $('[data-typeds]');
    el.each(function() {
      var $this = $(this);
      var str = $this.html();
      $this.addClass('uk-visibility-hidden');
      $this.after('<span data-typed-wrapper><span data-typed-init></span><span>');
      $this.next().find('[data-typed-init]').typeIt({
        strings: [str],
        autoStart: true,
        breakLines: true,
        speed: 40,
      });
    });
  }


})(jQuery);