(function ($) {
  if($('#modal-center .wpcf7').length){
    var idFormText = $('#modal-center .wpcf7').attr('id');
    var idForm = idFormText.split('-');
    var getId = idForm[1].slice(-idForm[1].length+1);
    document.addEventListener( 'wpcf7submit', function( event ) {
      if ( getId == event.detail.contactFormId ) {
        jQuery('.uk-pdf-download')[0].click();
        UIkit.modal('#modal-center').hide();
      }
    }, false );
  }

})(jQuery);