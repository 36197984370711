;(function ($) {

    var checboxesListCat = $('.uk-categories-list .uk-field-wrap');
    checboxesListCat.find('input').on('change', function () {
        if ($(this).prop('checked')) {
            console.log('true');
            $(this).removeClass('uk-check-none');
            $(this).parents('li').find('.uk-categories-list-child input').each(function () {
                if (!$(this).prop('checked')) {
                    $(this).prop('checked', true);
                }
            });
        } else {
            $(this).parents('li').find('.uk-categories-list-child input').each(function () {
                if ($(this).prop('checked')) {
                    $(this).prop('checked', false);
                }
            });
        }

    });

    checboxesListCat.find('label').on('click', function(){
        if($(this).hasClass('uk-parent-label')) {
            $(this).parent().toggleClass('uk-open');
            $(this).parents('.uk-categories').toggleClass('uk-slide-block');
            $(this).parents('.uk-categories').find('.uk-name-category').text($(this).data('name'));
        }
    });

    $('.uk-categories-list-child input').on('change', function () {
        var parentCheckbox = $(this).parents('li').find('.uk-field-wrap input');
        if (parentCheckbox.prop('checked')) {
            parentCheckbox.prop('checked', false).addClass('uk-check-none');
        }
    });

    $('.uk-reset-filter').on('click', function(e){
        e.preventDefault();
        $('.uk-categories-list input[type="checkbox"]').each(function () {
            if ($(this).prop('checked')) {
                $(this).prop('checked', false);
            }
        });
        $('.uk-search-faq-cat').submit();
    });

    $('.uk-filter-back').on('click', function () {
       $(this).parents('.uk-categories').removeClass('uk-slide-block');
       $(this).parents('.uk-categories').find('.uk-field-wrap').removeClass('uk-open');
    });

})(jQuery);