// jshint ignore: start

(function ($) {
  objectFitImages(false, {
    watchMQ: true,
    skipTest: true
  });

  function setCookie() {
    var href = window.location;
    var pathname = href.pathname;
    var newPath =  JSON.stringify(pathname.split('/', 3));

    if(pathname.split('/').length > 3) {
      localStorage.setItem('currentHref', newPath);
    }else {
      localStorage.removeItem('currentHref');
    }
  }

  setCookie();

  // $('.uk-article-body, .uk-section-content, .uk-card-content, .post-body').each(function(){
  //   var $this = $(this);
  //   var images = $this.find('img:not(.uk-logo)');
  //
  //   images.each(function () {
  //     var img = $(this);
  //     var imgSrc = img.attr('src');
  //     img.wrap('<a href="'+imgSrc+'"></a>');
  //     if (img.parents('figure').length != 0) {
  //       img.parents('figure').attr('uk-lightbox', true);
  //     }else {
  //       img.parent().wrap('<div uk-lightbox></div>')
  //     }
  //   });
  // });

  $('.uk-offcanvas-nav a').each(function () {
    var $this = $(this);
    var href = new URL($this.attr('href'));
    var pathname = href.pathname;
    var newPath =  JSON.stringify(pathname.split('/', 3));
    var currentHref = localStorage.getItem('currentHref');

    if (currentHref == newPath) {
      $this.parents('.menu-item').removeClass('uk-noactive');
      $this.parent('li').addClass('uk-active');
      $this.parents('.uk-first-level').addClass('uk-active');
    }
  });

  UIkit.util.on('.uk-navbar-dropdown', 'show', function () {
    if($(this).hasClass('uk-dropdown-mega-drop')) {
      $('.uk-header').addClass('overlay-active');
    }
  });

  UIkit.util.on('.uk-navbar-dropdown', 'hide', function () {
    if($(this).hasClass('uk-dropdown-mega-drop')) {
      $('.uk-header').removeClass('overlay-active');
    }
  });

  UIkit.util.on("#offcanvas", 'beforeshow', function(){
    $('li.uk-first-level.uk-active a.uk-dropdown-toggler').trigger('click');
  });

  $('.uk-breadcrumb a, .uk-card-cta .uk-button').on('click', function () {
    var href = location.href;
    localStorage.removeItem('currentHref');
  });
  var fields = $('.uk-input, .uk-select, .uk-textarea, .ginput_container input, .ginput_container textarea');
  var fieldsGroup = '.gfield, .uk-form-group, .uk-search-field, .wpcf7-form-control-wrap';
  function formIsEmpty(el) {
    $(el).each(function () {
      var $this = $(this);
      var val = $this.val() + '';
      if (val.length > 0 && val !== 'null') {
        $this.parents(fieldsGroup).addClass('uk-is-not-empty');
        $this.parents(fieldsGroup).removeClass('uk-is-empty');
      } else {
        $this.parents(fieldsGroup).addClass('uk-is-empty');
        $this.parents(fieldsGroup).removeClass('uk-is-not-empty');
      }
    });
  }

  formIsEmpty(fields);

  fields.on('change keyup focusin focusout', function () {
    formIsEmpty($(this));
  });


  $('body')
      .on('focusin', fields, function (e) {
        var $this = $(this);
        $(e.target).closest(fieldsGroup).addClass('uk-is-focus');
      }).on('focusout', fields, function (e) {
    var $this = $(this);
    $(e.target).closest(fieldsGroup).removeClass('uk-is-focus');
  });


  $(window).on('load resize', function () {
    $('textarea').trigger('input');
    $('textarea').textareaAutoSize();
  });


  $('.uk-search-close').on('click', function (e) {
    e.preventDefault();
    var $this = $(this);

    $this.closest('.uk-search-field').find('.uk-input').val('');
    formIsEmpty(fields);
  });
  $('[uk-nav]')
      .on('show', function () {
        $('body').addClass('uk-open-navsub');
      })
      .on('hide', function () {
        $('body').removeClass('uk-open-navsub');
      });

  $('.uk-offcanvas-navsub-close').on('click', function (e) {
    e.preventDefault();
    $('.uk-offcanvas-bar [uk-nav]').find('.uk-open > a')[0].click();
  });

  // var navbarDropActive = $('.uk-header .uk-active .uk-navbar-dropdown');
  // var navbarDropHeight = navbarDropActive.outerHeight();
  // $('.uk-navbar-desktop').append('<div class="uk-navbar-backdrop"></div>');
  // $('.uk-navbar-backdrop').css({'height': navbarDropHeight - 18});

  // $(window).on('resize', function () {
  //   navbarDropHeight = navbarDropActive.outerHeight();
  //   $('.uk-navbar-backdrop').css({'height': navbarDropHeight-18});
  // });
  if($('.uk-offcanvas-bar').find('.uk-open').length){
    $('body').addClass('uk-open-navsub');
  }




  // countTo init
  function countTo() {
    $('.uk-card-number').countTo();
    $('.uk-scroll-elem').removeClass('uk-active');
  }
  $(window).scroll(function(){
    if($('.uk-scroll-elem').hasClass('uk-active')) {
      countTo();

    }
  });

  $('.uk-card-category').on('mouseenter', function (e) {
    $(this).parents('.uk-card-category-list').find('>div>a').removeClass('uk-active');
  });

  // Slick
  function slickNavToggler() {
    $('[data-slick-nav]').on('click', function () {
      var $this = $(this);
      var data = $this.data('slick-nav');
      var stringify = JSON.stringify(data);
      var parse = JSON.parse(stringify);
      if (parse.next) {
        $(parse.next).slick('slickNext');
      }
      if (parse.prev) {
        $(parse.prev).slick('slickPrev');
      }

    });
  }

  function slickSlideCurrent() {
    var el = $('[data-slick-current]');
    el.each(function () {
      var $this = $(this);
      var data = $this.data('slick-current');
      var current = $(data).slick('slickCurrentSlide') + 1;
      var currentParse = current > 9 ? current : '0' + current;
      $this.text(currentParse);
    });
  }

  $('.slick-slider').each(function(){
    $(this).on('afterChange', function (event, slick, currentSlide, nextSlide) {

      slickSlideCurrent();

    });
  });

  function timeLine() {
    var d = new Date();
    var fullYear = d.getFullYear();
    var parrent = $('.uk-section-timeline');
    var yearStart = parrent.data('year-start');
    var countOneYear = 100 / (fullYear - yearStart);
    parrent.find('.uk-timeline-dot').each(function () {
      var $this = $(this);
      var $dataYear, $timelineWidth;
      $dataYear = $this.data('year');
      $timelineWidth = ($dataYear - yearStart) * countOneYear;
      $this.css('left', $timelineWidth + '%');
      $this.attr('data-width', $timelineWidth);
      if ($timelineWidth > 70) {
        $this.find('.uk-block-dot').addClass('uk-block-dot-reverse');
      }
    });
  }

  $($('.uk-timeline-dot').get().reverse()).each(function(i) {
    $(this).css('z-index', i);
  });
  timeLine();

  $('.uk-timeline-dot').on('mouseenter', function (e) {
    var $this = $(this);
    var blockWidth;
    blockWidth = $this.data('width');
    $this.parents('.uk-section-timeline').find('.uk-timeline-dot').removeClass('active');
    $this.addClass('active');
    $this.parents('.uk-section-timeline').find('.uk-timeline-line').css('width', blockWidth + '%');
  });

  $(window).on('scroll load',function() {
    if($('.uk-section-timeline').length) {
      var scrollTop =  $(window).scrollTop() + window.innerHeight;
      var offset =  $('.uk-section-timeline').offset().top + $('.uk-section-timeline').height();
      var blockWidth;
      blockWidth =  $('.uk-timeline-dot').data('width');

      if (scrollTop >= offset) {
        $('.uk-timeline-dot').eq(0).addClass('active');
        $('.uk-section-timeline').find('.uk-timeline-line').css('width', blockWidth + '%')
      }
    }


  });

  $('[data-slick-mousewheel]').on('mousewheel', function (event) {
    var $this = $(this);
    var slickSlider = $this.data('slick') ? $this : $this.data('slick-mousewheel') ? $this.find($this.data('slick-mousewheel')) : $this.find('[data-slick]');
    if (!slickSlider) return;
    var slickSliderCurrent = $(slickSlider).slick('slickCurrentSlide');
    var slickSlidersLength = $(slickSlider).find('.slick-slide').length;
    if (event.deltaX > 0 || event.deltaY < 0) {
      if (slickSliderCurrent == slickSlidersLength - 1) {
        return
      }
      event.preventDefault();
      $(slickSlider).slick('slickNext');
    }
    else if (event.deltaX < 0 || event.deltaY > 0) {
      if (slickSliderCurrent == 0) {
        return
      }
      event.preventDefault();
      $(slickSlider).slick('slickPrev');
    }
  });


  var $slider2 = $('.uk-section-business-cases.uk-section-slider-vertical [data-slick]');
  var $slider3 = $('.uk-section-main-secondary .uk-slider-vertical [data-slick]');

  function slickInit() {

    var $slider = $('[data-slick]');

    // scroll Ups

    $slider2.on('init', function (event, slick, currentSlide, nextSlide) {
      var current = $(this).find('.slick-current');

      var slideTyped = current.find('.slide-typed');
      $(window).scroll(function(){
        if($('.uk-scroll-ups').hasClass('uk-active')) {
          typedInit(slideTyped);
          $('.uk-scroll-ups').removeClass('uk-active');

        }
      });

    });

    $slider3.on('init', function (event, slick, currentSlide, nextSlide) {
      var current = $(this).find('.slick-current');

      var slideTyped = current.find('.slide-typed');
      typedInit(slideTyped);

    });

    $slider.on('afterChange', function (event, slick, currentSlide, nextSlide) {

      var current = $(this).find('.slick-current');

      if(!current.find('[data-typed-init]').length) {
        var slideTyped = current.find('.slide-typed');
        typedInit(slideTyped);
      }
      slickSlideCurrent();
    });

    $slider.on('init', function (event, slick, currentSlide, nextSlide) {
      var slickDots = jQuery('.uk-business-cases-info .slick-dots li');
      if(slickDots.length == 1) {slickDots.parent('.slick-dots').hide()}
    });


    // @breakpoint-small:                             640px;  // Phone landscape
    // @breakpoint-medium:                            960px;  // Tablet Landscape
    // @breakpoint-large:                             1200px; // Desktop
    // @breakpoint-xlarge:                            1600px; // Large Screens

    $slider.each(function() {
      var $slider = $(this);
      var data = $slider.data('slick');
      // var typedOnCurrentSlide = data.typedOnCurrentSlide;
      //
      // console.log(typedOnCurrentSlide);

      $slider.find('[data-typed]').addClass('slide-typed uk-visibility-hidden');
      $slider.find('[data-typed]').removeAttr('data-typed');

      // console.log($slider.find('[data-typed]'));

      $slider.slick({
        // slidesToShow: data.slidesToShow,
        // slidesToScroll: data.slidesToScroll,
        // swipeToSlide: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: data.items ? data.items[0] : 3
            }
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: data.items ? data.items[1] : 3
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: data.items ? data.items[2] : 2,
              vertical: false,
              verticalSwiping: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: data.items ? data.items[3] : 1,
              vertical: false,
              verticalSwiping: false,
            }
          }
        ]
      });
    })
  }

  slickInit();
  slickNavToggler();
  slickSlideCurrent();

  $('.sticky-title').append('<span class="sticky-title-background"></span>');
  $(window).on('load', function() {
    var headerHeight = $('.uk-header').outerHeight();
    $('.uk-sticky-placeholder').css('height',headerHeight);
  }).on('load scroll', function() {
    var $this = $(this);
    var scrollWindow = $this.scrollTop();
    var scrollWithElements = scrollWindow + $('.uk-header.uk-sticky').outerHeight();
    $('.sticky-title').each(function() {
      var $this = $(this);
      var headerHeight = $('.uk-header').outerHeight();
      var bg = $this.find('.sticky-title-background');
      $('.uk-section-sticky').attr('uk-sticky','offset:'+headerHeight);
      var offsetTop = $this.closest('.uk-sticky').next('.uk-sticky-placeholder').offset().top;
      var offsetTopWithElements = offsetTop - $('.uk-header.uk-sticky').outerHeight();
      var percent = (scrollWindow - offsetTopWithElements).toFixed();
      if(percent > 0) {
        bg.css('width', percent + '%');
      } else {
        bg.css('width', '');
      }
      if (percent >= 100) {
        $this.addClass('uk-active');
      } else {
        $this.removeClass('uk-active');
      }

      if (offsetTopWithElements < 0) {
        $this.removeClass('uk-active');
      }
    });
  });


  $('.slider-ervaringen').slick({
    slidesToShow: 1,
    infinite: false,
    arrows: false,
  });
  // var stHeight = $('.slick-track').height();
  // $('.slick-slide').css('height',stHeight + 'px' );

  function typedInit(el) {
    var el = el || $('[data-typed]');
    el.each(function() {
      var $this = $(this);
      var str = $this.html();
      $this.addClass('uk-visibility-hidden');
      $this.after('<span data-typed-wrapper><span data-typed-init></span><span>');
      $this.next().find('[data-typed-init]').typeIt({
        strings: [str],
        // autoStart: false,
        breakLines: true,
        speed: 40,
        callback: function(){
          setTimeout(function(){
            $slider2.slick('slickNext');
          }, 4000);
          return false;
        }
      });
    });

  }

  // BC form to single page

  var getUrl;
  var form_id;
  jQuery('.uk-card-business-case .uk-card-link a, .uk-section-content-with-tab .uk-card-link a').on('click', function(){
    getUrl = jQuery(this).data('link');
    form_id = jQuery(this).data('formid');
  });
  $(document).bind('gform_confirmation_loaded', function(event, formId){

    if ( formId == form_id ) {
      location = getUrl;
    }
  });

  $(document).bind('gform_post_render', function () {
    formIsEmpty(fields);
  });

  $('iframe').each(function(){
    $(this).wrap('<div class="uk-has-iframe"></div>');
  })

})(jQuery);


/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  //Ajax blog
  //Ajax paging
  function ajaxLoadBlog() {
    $('.uk-content-blog .uk-pagination a').on('click', function () {
      var num = $(this).data('page');
      var cat_id = $('.cat_id').text();
      $('.uk-content-blog .uk-pagination-pages li').removeClass('uk-active');
      $(this).parent('li').addClass('uk-active');
      $.ajax({
        method: 'post',
        data: {
          action: 'ajax_pagination',
          num: num,
          id: cat_id,
        },
        dataType: 'html',
        url: ajaxurl,
        beforeSend: function () {
          $('#loader-search').show();
        },
        success: function (html) {
          $('.uk-content-blog').not('.uk-tab-categories').empty();
          $(html).appendTo('.uk-content-blog');
          $('.uk-content-blog .uk-grid').hide().fadeIn();
          // window.history.pushState('page', 'Title', pathname);

          ajaxLoadBlog();
        }
      });
      return false;
    });
  }

  function ajaxFilterBlog() {
    $('.uk-section-blog .uk-tab-categories a').on('click', function () {
      $('.uk-search input[type="search"]').val('');
      $('.uk-search-field').removeClass('uk-is-focus');
      var id = $(this).data('id');
      var pathname = $(this).attr('href');
      var pagenum = 1;
      $('.cat_id').text(id);
      $('.uk-tab-categories li').removeClass('uk-active');
      $(this).parent('li').addClass('uk-active');
      $.ajax({
        method: 'post',
        data: {
          action: 'filter_blog',
          id: id,
          pagenum: pagenum,
        },
        dataType: 'html',
        url: ajaxurl,
        beforeSend: function () {
          $('#loader-search').show();
        },
        success: function (html) {
          $('.uk-content-blog').not('.uk-tab-categories').empty();
          $(html).appendTo('.uk-content-blog');
          $('.uk-content-blog .uk-grid').hide().fadeIn();
          ajaxLoadBlog();
          // window.history.pushState('page', 'Title', pathname);
        }
      });
      return false;
    });
  }

  //Ajax paging
  function ajaxLoadBlog() {
    $('.uk-content-blog .uk-pagination a').on('click', function () {
      var num = $(this).data('page');
      var cat_id = $('.cat_id').text();
      if ($('.uk-search input[type="search"]').val()) {
        var s = $('.uk-search input[type="search"]').val();
      }
      $('.uk-content-blog .uk-pagination-pages li').removeClass('uk-active');
      $(this).parent('li').addClass('uk-active');
      $.ajax({
        method: 'post',
        data: {
          action: 'ajax_pagination',
          num: num,
          id: cat_id,
          s: s,
        },
        dataType: 'html',
        url: ajaxurl,
        beforeSend: function () {
          $('#loader-search').show();
        },
        success: function (html) {
          $('.uk-content-blog').not('.uk-tab-categories').empty();
          $(html).appendTo('.uk-content-blog');
          $('.uk-content-blog .uk-grid').hide().fadeIn();
          // window.history.pushState('page', 'Title', pathname);

          ajaxLoadBlog();
        }
      });
      return false;
    });
  }

  //Ajax Faq search
  $('.uk-section-faq').on( 'submit', '.uk-search-faq',function (e) {
    e.preventDefault();
    var $section = $(this).closest('.uk-section-faq'),
        cat = $(this).find('input[type="search"]').attr('data-post-cat'),
        posts_in = $(this).find('input[type="search"]').attr('data-post-in'),
        s = $(this).find('input[type="search"]').val();
    $.ajax({
      method: 'post',
      data: {
        action: 'ajax_search_faq',
        s: s,
        cat: cat,
        posts_in: posts_in,
        posts_per_page: -1,
        template: 'faq_module/components/list.twig'
      },
      dataType: 'html',
      url: ajaxurl,
      beforeSend: function () {
        $('#loader-search').show();
      },
      success: function (html) {
        $('.data-ajaxer', $section).html(html);
        $('.data-ajaxer', $section).hide().fadeIn();
        // window.history.pushState('page', 'Title', pathname);
      }
    });
  });

  //Ajax Faq Category search

  $('.uk-search-faq-cat').keypress(function (e) {
      var key = e.which,
        $this = $(this);
      if(key == 13) {
          $('input[type="search"]', $this).data('paged', '1');
      }
  });

  $('.uk-section-faq-cat').on( 'submit', '.uk-search-faq-cat',function (e) {
    var $section = $(this).closest('.uk-section-faq-cat');
    var $form = $(this);
    e.preventDefault();
    var cat = [];
    $('[name="cat"]:checked', $section).each(function(){
      cat.push($(this).val());
    });
    var posts_in = $('input[type="search"]', $form).attr('data-post-in');
    var s = $('input[type="search"]', $form).val();
    var order = $('input[type="search"]', $form).data('order').toUpperCase();
    var paged = $('input[type="search"]', $form).data('paged');
    $.ajax({
      method: 'post',
      data: {
        action: 'ajax_search_faq',
        s: s,
        cat: cat,
        posts_in: posts_in,
        order: order,
        posts_per_page: 12,
        paged: paged,
        template: 'faq_cat_module/components/list.twig'
      },
      dataType: 'html',
      url: ajaxurl,
      beforeSend: function () {
        $('#loader-search').show();
      },
      success: function (html) {
        $('.data-ajaxer', $section).html(html);
        $('.data-ajaxer', $section).hide().fadeIn();
        var results = $('.uk-list-faq > li', $section).length;
        $('.uk-results span', $section).text(results);

        $("html,body").animate({scrollTop: $(".uk-section-faq-cat").offset().top-100}, 500);
      }
    });
  })
  .on( 'change', '[name="cat"]', function (e) {
    var $form = $(this).closest('.uk-search-faq-cat');
    $('input[type="search"]', $form).data('paged', '1');
    $form.trigger('submit');
  })
  .on( 'click', '.uk-search-sort .uk-dropdown-nav', function (e) {
    e.preventDefault();
    var $section = $(this).closest('.uk-section-faq-cat');
    var $form = $('.uk-search-faq-cat', $section);
    var $parent = $(this).closest('.uk-search-sort');
    var order = $(e.target).data('order');
    var text = $(e.target).text();
    var $btn = $('.uk-sort-button', $parent);
    $('input[type="search"]', $form).data('order', order);
    $btn.text(text);

    $form.trigger('submit');
  }).on( 'click', '.uk-pagination a', function (e) {
    e.preventDefault();
    var $section = $(this).closest('.uk-section-faq-cat');
    var $form = $('.uk-search-faq-cat', $section);
    var paged = $(e.target).data('page');
    $('input[type="search"]', $form).data('paged', paged);

    $form.trigger('submit');
  }).on('click', '.uk-search-button', function (e) {
    var $section = $(this).closest('.uk-section-faq-cat');
    $('input[type="search"]', $section).data('paged', '1');
  }).on('keypress', '.uk-search-faq-cat', function (e) {
    var key = e.which;
    if(key == 13) {
      var $section = $(this).closest('.uk-section-faq-cat');
      $('input[type="search"]', $section).data('paged', '1');
    }
  });

  ajaxLoadBlog();
  ajaxFilterBlog();
  //Autocomplete blog search

  var inc;
  jQuery('.uk-search input[type="search"]').on('blur', function(){
    inc = 0;
  }).on('focus', function(){
    setTimeout(function(){
      if($('.uk-navbar-content .uk-search-field').hasClass('uk-is-focus')) {
        inc = 1;
      }
    },200)
  });




  $('.uk-search input[type="search"]').autoComplete({
    source: function (name, response) {
      $.ajax({
        type: 'POST',
        dataType: 'json',
        url: ajaxurl,
        data: 'action=get_listing_names&name=' + name +'&inc=' + inc,
        success: function (data) {
          response(data);
        }
      });
    },
    renderItem: function (item, search) {
      search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      var re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
      return '<div class="autocomplete-suggestion" data-val="' + search + '"><a href="' + item.link + '">' + item.title.replace(re, "<b>$1</b>") + '</a></div>';
    },
    onSelect: function () {
      ajaxSearchBlog();
    }
  });

  //Search blog ajax

  $('.uk-search').submit(function (e) {
    e.preventDefault();
    $('.cat_id').text('');
    var s = $(this).find('input[type="search"]').val();
    var pagenum = 1;
    $.ajax({
      method: 'post',
      data: {
        action: 'ajax_search',
        s: s,
        pagenum: pagenum,
      },
      dataType: 'html',
      url: ajaxurl,
      beforeSend: function () {
        $('#loader-search').show();
      },
      success: function (html) {
        $('.uk-content-blog').not('.uk-tab-categories').empty();
        $(html).appendTo('.uk-content-blog');
        $('.uk-content-blog .uk-grid').hide().fadeIn();
        ajaxLoadBlog();
        // window.history.pushState('page', 'Title', pathname);
      }
    });
  });

  function noteFootIndex() {
    if ($('.uk-note-editor').length) {
      $(".uk-notefoot-holder").append('<ul class="uk-footnotes-list uk-list uk-list-literature">');
    } else {
      $(".uk-notefoot-holder").prev('h4').hide();
      return false;
    }

    $('.uk-footnote-body').each(function (i) {
      var i = i + 1;
      var textNote = $(this).html();
      $(".uk-footnotes-list").append('<li class="uk-note-' + i + '"><a href="#note-' + i + '" uk-scroll="offset: 80">(' + i + ')</a>' + textNote + '</li>');
    });
  }

  noteFootIndex();

  function formStyle() {
    $('.wpcf7-form input').each(function(){
      var $this = $(this);
      var name = 'uk';
      var type = $this.attr('type');
      switch(type){
        case 'radio':
        case 'checkbox':
          name += '-'+$this.attr('type');
          $this.attr('class',name);
      }

    })
  }
  formStyle();

  function getDateTime() {
    var dt = new Date();
    var month = new Array();
    month[0] = "januari";
    month[1] = "februari";
    month[2] = "maart";
    month[3] = "april";
    month[4] = "mei";
    month[5] = "juni";
    month[6] = "juli";
    month[7] = "augustus";
    month[8] = "september";
    month[9] = "oktober";
    month[10] = "november";
    month[11] = "december";
    var minutes = dt.getMinutes();
    if(minutes>9) {
      minutes = minutes;
    }else {
      minutes = '0'+minutes;
    }
    var hours = dt.getHours();
    var time = hours+':'+minutes;
    var date = dt.getDate()+' '+month[dt.getMonth()]+' '+dt.getFullYear();
    localStorage.setItem('time', time);
    localStorage.setItem('date', date);

  }
  $('.date-str').text(localStorage.getItem('date'));
  $('.time-str').text(localStorage.getItem('time'));
  getDateTime();
  // $(window).on('load',function(){
  //     var hyphenatorSettings = {
  //         displaytogglebox :	false
  //     };
  //     Hyphenator.config(hyphenatorSettings);
  //     Hyphenator.run();
  // });


  // Hyphenator.run();

  // breadcrumbs
  if($('.uk-breadcrumb').length){
    $('.uk-breadcrumb').empty();
    var currentUrl = location.href.split('?')[0];

    if(currentUrl.substr(-1) == '/') {
      currentUrl = currentUrl.substr(0, currentUrl.length - 1);
    }

    var here = currentUrl.split('/').slice(3);

    var parts = [{ "text": 'Home', "link": '/' }];
    for( var i = 0; i < here.length; i++ ) {
      var part = here[i];
      var textObj = part.charAt(0).toUpperCase() + part.slice(1);
      var text = textObj.split('-').join(' ');

      var link = '/' + here.slice( 0, i + 1 ).join('/');
      //var link = link1.replace('reken-software', 'rekensoftware');
      parts.push({ "text": text, "link": link });

    }
    var sizeObj = parts.length;
    for (var k= 0; k < sizeObj; k++ ) {
      if(k == sizeObj-1) {
        $('.uk-breadcrumb').append('<span>'+ parts[k].text.split(' ').join(' ') +'</span>');
      }else {
        if(k==1) {
          $('.uk-breadcrumb').append('<a href="'+parts[1].link+'">'+ parts[1].text.split(' ').join('') +'</a>');
        }else {
          $('.uk-breadcrumb').append('<a href="'+parts[k].link+'">'+ parts[k].text +'</a>');
        }
      }

    }

  }

  // Whitepapper pdf download

  var arr_sort = new Array();

  function removeVal(arr, val)
  {
    for(var i = 0; i < arr.length; i++)
    {
      if (arr[i] == val)
        arr.splice(i, 1);
    }
  }

  $("body").on("click", ".gfield_checkbox input[name*='input']", function() {
    if ($(this).is(':checked')){
      arr_sort.push($(this).val());
    }else {
      removeVal(arr_sort, $(this).val());
    }
  });

  jQuery(document).on("gform_post_render", function (e, form_id){
    if($('.gfield .gform_hidden').val() == form_id) {
      setTimeout(function () {
        if ($('.validation_error').length === 0) {
          arr_sort.forEach(function (element) {
            window.open(element);
          });
        }
      }, 300)
    }
  });
  // Menu hover
  $('.uk-navbar-content .uk-navbar-nav > li:not(.uk-active) > a').hover(function(){
    $(this).parents('.uk-navbar-nav').find('li.uk-active').toggleClass("uk-hover");
  })

  // Search

  var delay = (function () {
    var timer = 0;
    return function (callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();
  var searchModalRequest = {
    props: {
      modalTarget: '.uk-modal',
      modalsearch: 'uk-modal-search',
    },
    init: function () {
      var self = this;
      this.targetActivatesearchInput(this.props.modalTarget);
      $('.module-menu-search, .module-menu-search-body').on('keyup', function () {
        var $this = $(this);
        delay(function () {
          self.ajaxRequest($this);
        }, 500);
      });
    },
    targetActivatesearchInput: function (item) {
      $(item).on({
        'show.uk.modal': function (e) {
          var modalTarget = $(e.target).find('input');
          setTimeout(function () {
            modalTarget.eq(0).focus();
          });
        },
      });
    },
    actionShowMore: function () {
      $('body').on('click', '.uk-show-more', function (e) {
        e.preventDefault();
        var list = $(this).parents('.uk-search-posts').find('.uk-list-posts');
        if (list.hasClass('is_open')) {
          list.css('height', list.data('height'));
          list.removeClass('is_open');
        } else {
          list.css('height', list.data('full-height'));
          list.addClass('is_open');
        }
      });
    },
    ajaxRequest: function (element) {
      var data = {
        'action': 'module_menu_search',
        's': element.val(),
        'query': element.data('query'),
      };
      $.ajax({
        url: window.ajaxurl,
        type: "POST",
        data: data,
        beforeSend: function () {
          var $el = $("body");
          $el.find('.uk-search-result svg').removeClass('uk-hidden');
          $el.find('.uk-search-count, .uk-search-label').hide();
        },
        success: function (response) {
          response = JSON.parse(response);
          var $el = $("body");
          $el.find('.uk-search-result svg').addClass('uk-hidden');
          if (response.count){
            $el.find('.uk-search-menu-list').html(response.view);
          } else{
            $el.find('.uk-search-menu-list').html('<div class="uk-empty-results">'+response.empty_results+'</div>');
          }

          $el.find('.uk-search-count').html(response.count).show();
          if (response.count_text) {
            $el.find('.uk-search-label').html(response.count_text).show();
          }
          // self.showLoadingMore();

        },
      });
    },
  };

  searchModalRequest.init();

  // Scroll nav

  var next = 0;
  var $window = $(window);
  var nav = $('.uk-navbar-nav>li.uk-active>.uk-navbar-dropdown');

  $window.on('scroll', function(){
    var scrollTop = $window.scrollTop();
    nav.toggleClass('uk-show', scrollTop < next);
    next = scrollTop;
  });

  // hubspot label animation
  $window.on('load', function(e){
    if($('.uk-hubspot-wrapper').length){
      $('.uk-hubspot-wrapper .field').on('click', function(){
        $(this).find('label').addClass('uk-field-active');
        $(document).mouseup(function (e) {
          if ($(e.target).closest('.uk-hubspot-wrapper .field label').length === 0) {
            $(this).find('.field label').removeClass('uk-field-active');
          }
        });
      });
    }
  });

  // wrap table
  $('.uk-article-body table').each(function(){
    $(this).wrap('<div class="uk-overflow-auto"></div>');
  });

  // Custom select

  var x, i, j, l, ll, selElmnt, a, b, c;
  /*look for any elements with the class "custom-select":*/
  x = document.getElementsByClassName("custom-select");
  l = x.length;
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    ll = selElmnt.length;
    /*for each element, create a new DIV that will act as the selected item:*/
    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);
    /*for each element, create a new DIV that will contain the option list:*/
    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");
    for (j = 0; j < ll; j++) {
      /*for each option in the original select element,
      create a new DIV that will act as an option item:*/
      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.addEventListener("click", function(e) {
        /*when an item is clicked, update the original select box,
        and the selected item:*/
        var y, i, k, s, h, sl, yl;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        sl = s.length;
        h = this.parentNode.previousSibling;
        for (i = 0; i < sl; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i;
            h.innerHTML = this.innerHTML;
            y = this.parentNode.getElementsByClassName("same-as-selected");
            yl = y.length;
            for (k = 0; k < yl; k++) {
              y[k].removeAttribute("class");
            }
            this.setAttribute("class", "same-as-selected");
            break;
          }
        }
        h.click();
      });
      b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function(e) {
      /*when the select box is clicked, close any other select boxes,
      and open/close the current select box:*/
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
  }
  function closeAllSelect(elmnt) {
    /*a function that will close all select boxes in the document,
    except the current select box:*/
    var x, y, i, xl, yl, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i)
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }
  /*if the user clicks anywhere outside the select box,
  then close all select boxes:*/
  document.addEventListener("click", closeAllSelect);

})(jQuery); // Fully reference jQuery after this point.
