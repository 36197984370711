(function ($) {

  jQuery('.uk-article-landing .uk-article-body h2').each(function(){
    var getText = jQuery(this).html();
    var getFirstSimbol = getText.slice(0, 1);
    var getFullText = getText.slice(-getText.length+1);
    if(getFirstSimbol == "/"){
      jQuery(this).empty().append( "<span class='uk-title-slash'>"+getFirstSimbol+"</span>"+getFullText );
    }
  });

  // Less/minder

  jQuery('.uk-lees-minder').each(function(i){
    jQuery(this).parent().attr('data-minder', i);
    jQuery('[data-minder="'+i+'"]').hide();
    jQuery('[data-minder]').on('click', function(){
      var count = jQuery(this).data('minder');
      var dataMeer = jQuery('[data-meer="'+count+'"]');
      dataMeer.nextUntil('[data-minder="'+count+'"]').hide();
      dataMeer.show();
      jQuery('[data-minder="'+count+'"]').hide();
    })
  });

  jQuery('.uk-lees-meer').each(function(i){
    jQuery(this).parent().attr('data-meer', i);
    jQuery('[data-meer="'+i+'"]').nextUntil('[data-minder="'+i+'"]').hide();
    jQuery('[data-meer]').on('click', function(){
      var count = jQuery(this).data('meer');
      var dataMeer = jQuery('[data-meer="'+count+'"]');
      dataMeer.nextUntil('[data-minder="'+count+'"]').show();
      dataMeer.hide();
      jQuery('[data-minder="'+count+'"]').show();
    })
  });

})(jQuery);