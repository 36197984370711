(function ($) {
  if($('div').hasClass('uk-section-slider-landing')) {
    $('.uk-section-slider-landing .slider-box').each(function () {
      var parent = $(this).parents('.uk-section-slider-landing'),
          currentIndex = parent.find('.uk-slider-current'),
          $carousel = $(this);

      $carousel.on('init', function(event, slick, currentSlide, nextSlide){
        var $count = 3;
        if($(window).width() < 600) {
          $count = 1;
        }else if ($(window).width() < 1024) {
          $count = 2;
        }
        currentIndex.text('0' + $count);
      });
      $carousel.slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        appendDots: $('.slick-slider-dots'),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      });

      $carousel.on('afterChange', function (event, slick, currentSlide) {
        var $count = 3;
        if($(window).width() < 600) {
          $count = 1;
        }else if ($(window).width() < 1024) {
          $count = 2;
        }

        if (currentSlide < 9) {
          currentIndex.text('0' + (currentSlide + $count));
        } else {
          currentIndex.text(currentSlide + $count);
        }
      });
    });
  }

})(jQuery);
